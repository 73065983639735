/* eslint-disable no-param-reassign */
/* eslint-disable arrow-body-style */
/* eslint-disable radix */
import numeral from 'numeral';

/*
  Count length of given decimal
*/
function countLengthDecimal(num) {
  const match = `${num}`.match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
  if (!match) {
    return 0;
  }
  return Math.max(
    0,
    // Number of digits right of decimal point.
    (match[1] ? match[1].length : 0) -
      // Adjust for scientific notation.
      (match[2] ? +match[2] : 0),
  );
}

/*
  Get random integer from in range of min to max value
*/
function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

/**
 * Get total digit of a number
 * @param {number} num
 */
function getNumberDigit(num) {
  const netral = Math.abs(num);
  return netral.toString().length;
}

/**
 * Shorten thousands number
 * @param {number} num
 * @param {number} fixed
 * @param {boolean} symbol
 */
export function kNumberFormatter(
  num,
  fixed = 2,
  symbol = false,
  withComa = false,
) {
  let number;
  const nd = getNumberDigit(num);
  if (nd > 3) {
    number = (num / (nd > 5 ? 100000 : 1000)).toFixed(fixed);
    if (!withComa) {
      number = numeral(number).format('0,0');
    } else if (withComa) {
      number = numeral(number).format(
        typeof withComa === 'string' ? withComa : '0,0.00',
      );
    }

    if (symbol) {
      number += 'K';
    }
  } else {
    number = num;
  }

  return number;
}

/**
 * Shorten long number and add suffix M
 * @param {number} num - Long number
 * @param {number} fixed - Decimal
 * @param {boolean} symbol - Flag to add symbol suffix
 */
function mNumberFormatter(num, fixed = 2, symbol = false) {
  let number;
  let decide = false; // is decide need number
  if (getNumberDigit(num) > 6) decide = true;
  if (decide) {
    number = (num / 1000000).toFixed(fixed);
    if (symbol) {
      number += 'M';
    }
  } else {
    number = num;
  }
  return number;
}

/**
 * Shorten long number and add suffix B
 * @param {number} num - Long number
 * @param {number} fixed - Decimal number
 * @param {boolean} symbol - Flag to add symbol suffix
 */

export function bNumberFormatter(
  num,
  fixed = 2,
  symbol = false,
  formatDot = false,
) {
  let number = (num / 1000000000).toFixed(fixed);
  if (symbol) {
    if (formatDot) {
      number = numeral(number).format('0.0');
    } else {
      number = numeral(number).format('0,0[.]0');
    }
    number += 'B';
  }

  return number;
}

function tNumberFormatter(num, fixed = 2, symbol = false) {
  let number = (num / 1000000000000).toFixed(fixed);
  if (symbol) {
    number = numeral(number).format('0.0');
    number += 'T';
  }

  return number;
}

/**
 * Function to decide which number formatter should be used based on
 * the number length
 * @param {number} num
 * @param {boolean} withThousands
 * @param {boolean} formatDot
 */
export function decideNumberFormatter(num, withThousands, formatDot) {
  const numberDigit = getNumberDigit(num);
  if (numberDigit > 12 && formatDot) return tNumberFormatter;
  if (numberDigit > 9) return bNumberFormatter;

  if (withThousands) {
    if (numberDigit > 6) return mNumberFormatter;

    return kNumberFormatter;
  }

  return mNumberFormatter;
}

/**
 * Number formatter wrapper
 * @param {Object} params
 * @param {*} params.num - Number to be formatted
 * @param {number} params.fixed - Decimal place
 * @param {boolean=} params.symbol - If formatted number has symbol suffix
 * @param {boolean=} params.withThousands - If decided to format thousands number
 * @param {*=} params.formatDot - Using dot in formatting
 * @returns {string | number} -formatted number
 */
function numberFormatter({
  num,
  fixed,
  symbol = true,
  withThousands = false,
  formatDot = false,
}) {
  const formatter = decideNumberFormatter(
    num,
    withThousands,
    Boolean(formatDot),
  );

  return formatter(num, fixed, symbol, formatDot);
}

const char_count = (str, letter) => {
  let letter_Count = 0;
  for (let position = 0; position < str.length; position += 1) {
    if (str.charAt(position) === letter) {
      letter_Count += 1;
    }
  }
  return letter_Count;
};

export const roundNumber = (number, format) => {
  const nFormat = format.split('.');
  let multiplier = 1;
  if (nFormat.length > 1) {
    const getDecimal = nFormat.pop();
    const decimal = char_count(getDecimal, '0');
    multiplier = 10 ** decimal;
  }

  const nNumber =
    (number >= 0 ? Math.floor : Math.ceil)(number * multiplier) / multiplier;

  return nNumber;
};

export const isFloat = (n) => Number(n) % 1 !== 0;

const processNumber = (number, format, showTrailingZero) => {
  const formattedNumber = numeral(number).format(format);
  let roundingNumber = formattedNumber;
  const splitNumber = formattedNumber.split('.');
  const isDecimal = splitNumber.length > 1;

  if (!showTrailingZero) {
    if (isDecimal) {
      roundingNumber = formattedNumber.replace(/(\.0+|0+)$/, '');
    } else if (splitNumber.length) {
      // eslint-disable-next-line prefer-destructuring
      roundingNumber = splitNumber[0];
    }
  }

  return roundingNumber;
};

export const formatNumber = (
  number,
  rounding = true,
  format = '0,0',
  showTrailingZero = false,
) => {
  const roundingNumber = rounding ? roundNumber(number, format) : number;
  const newNumber = processNumber(roundingNumber, format, showTrailingZero);
  return newNumber;
};

export const formatPercentage = (
  number,
  rounding = true,
  format = '0.00',
  showTrailingZero = false,
) => {
  if (number === Infinity) number = 0;
  const roundingNumber = rounding ? roundNumber(number, format) : number;
  const newNumber = processNumber(roundingNumber, format, showTrailingZero);
  return `${newNumber}%`;
};

const parseLot = (lot) => Math.round(lot / 100);

export const parseCountAbbrv3 = (
  number,
  decPlaces,
  abbreviation = 1,
  withDecimal = false,
  withMinusSymbol = false,
) => {
  let result = 0;
  let minus = false;
  let newNumber = number;

  // eslint-disable-next-line eqeqeq
  if (newNumber && newNumber.toString().indexOf('-') == 0) {
    minus = true;
  }

  newNumber = Math.ceil(newNumber);

  if (!decPlaces) decPlaces = 3;
  // 2 decimal places => 100, 3 => 1000, etc
  decPlaces = 10 ** decPlaces;

  let abbrev;

  // Enumerate number abbreviations
  // eslint-disable-next-line default-case
  switch (abbreviation) {
    case 1:
      // Enumerate number abbreviations
      abbrev = ['K', 'M', 'B', 'T'];
      break;
    case 2:
      // Enumerate number abbreviations
      abbrev = ['K', 'M', 'B'];
      break;
    case 3:
      // Enumerate number abbreviations
      abbrev = ['K', 'M'];
      break;
    case 4:
      // Enumerate number abbreviations
      abbrev = ['K'];
      break;
  }

  // Go through the array backwards, so we do the largest first
  // eslint-disable-next-line no-plusplus
  for (let i = abbrev.length - 1; i >= 0; i--) {
    // Convert array index to "1000", "1000000", etc
    const size = 10 ** ((i + 1) * 3);

    // If the number is bigger or equal do the abbreviation
    if (size <= newNumber) {
      // Here, we multiply by decPlaces, round, and then divide by decPlaces.
      // This gives us nice rounding to a particular decimal place.

      newNumber = Math.round((newNumber * decPlaces) / size) / decPlaces;

      // Handle special case where we round up to the next abbreviation
      if (newNumber === 1000 && i < abbrev.length - 1) {
        newNumber = 1;
        // eslint-disable-next-line no-plusplus
        i++;
      }

      const format = withDecimal ? '0,0[.]0' : '0,0';
      newNumber = numeral(newNumber).format(format);

      if (minus) {
        newNumber = withMinusSymbol ? `-${newNumber}` : `(${newNumber})`;
      }

      // Add the letter for the abbreviation
      newNumber += `${abbrev[i]}`;

      // We are done... stop
      break;
    }
  }

  if (number) {
    const numString = newNumber.toString();
    result = numString;
  }

  return result;
};

const isNum = (n) => {
  return !Number.isNaN(parseFloat(n)) && Number.isFinite(n);
};

const addPlusOrMinus2 = (value) => {
  if (!isNum(value)) {
    return value;
  }
  const formattedValue = numeral(value).value();
  let newValue = parseFloat(formattedValue);
  if (formattedValue !== 0) {
    newValue =
      formattedValue < 0
        ? numeral(newValue).format('0,0')
        : `+${numeral(newValue).format('0,0')}`;
  }
  return typeof newValue !== 'string' ? String(newValue) : newValue;
};

export const parseCountThousand = (num, withdot, separator = ',') => {
  // let sep = ','
  try {
    if (withdot) {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);
    }
    return removeCountDotThousand(
      num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator),
    );
  } catch (e) {
    return num;
  }
};

export const parseCountThousandWithDecimalPlace = (num, decimalPlace = 2) => {
  // let sep = ','
  try {
    return removeCountDotThousand(num.toLocaleString());
  } catch (e) {
    return num;
  }
};

export function parsePureValueSep(pv, thoSep = ',') {
  // console.log('::-- parsePureValue ', {pv, thoSep})
  if (typeof pv !== 'number') pv = parseFloat(pv);
  return parseFloat(pv.toFixed(2)).toLocaleString('en-US');
}

export const parseCountAbbrv2 = (
  number,
  decPlaces,
  thoSep = ',',
  enableK = false,
) => {
  let result = 0;

  if (typeof decPlaces !== 'number') decPlaces = 3;
  // 2 decimal places => 100, 3 => 1000, etc
  decPlaces = Math.pow(10, decPlaces);

  // Enumerate number abbreviations
  const abbrev = ['K', 'M', 'B'];
  const oldNumber = number;

  // Go through the array backwards, so we do the largest first
  for (let i = abbrev.length - 1; i >= 0; i--) {
    // Convert array index to "1000", "1000000", etc
    const size = Math.pow(10, (i + 1) * 3);

    // If the number is bigger or equal do the abbreviation
    if (size <= number) {
      // Here, we multiply by decPlaces, round, and then divide by decPlaces.
      // This gives us nice rounding to a particular decimal place.
      number = Math.round((number * decPlaces) / size) / decPlaces;

      // Handle special case where we round up to the next abbreviation
      if (number == 1000 && i < abbrev.length - 1) {
        number = 1;
        i++;
      }

      // Add the letter for the abbreviation
      number += `${abbrev[i]}`;

      // We are done... stop
      break;
    }
  }

  let raw = [];

  if (number) {
    number = number.toString();
    raw = number.split(' ');
    if (raw[0] && raw[1]) {
      // console.log('::-- row raw ', parsePureValueSep(raw[0], thoSep));
      result = parsePureValueSep(raw[0], thoSep);
      if (raw[1] === 'K' && !enableK) {
        result = parseFloat(oldNumber);
        result = parsePureValueSep(result, thoSep);
      } else {
        result = `${result} ${raw[1]}`;
      }
    } else {
      result = number;
    }
  }

  //   if(number) {
  // result = number.replace('.', ',')
  //   }

  return result;
};

export function parseNumberAbbrvNum(val, format) {
  const number = numeral(val);
  const formatted = number.format(format);
  return formatted;
}

const parseCountAbbrv4 = ({
  value,
  decPlaces,
  abbreviation = 1,
  withDecimal = false,
  withMinusSymbol = false,
}) =>
  parseCountAbbrv3(
    value,
    decPlaces,
    abbreviation,
    withDecimal,
    withMinusSymbol,
  );

/**
 * Shorten thousands number
 * @param {number} num
 * @param {number} fixed
 * @param {boolean} symbol
 * @param {boolean} withComma
 */
function kNumberFormatterV2(num, fixed = 2, symbol = false, withComma = false) {
  let number;
  const nd = getNumberDigit(num);
  if (nd > 3) {
    number = (num / 1000).toFixed(fixed);
    if (!withComma) {
      number = numeral(number).format('0,0');
    } else {
      number = numeral(number).format(
        typeof withComma === 'string' ? withComma : '0,0.0',
      );
    }

    if (symbol) {
      number += 'K';
    }
  } else {
    number = num;
  }

  return number;
}

const numberFormat = ({
  value,
  decimals,
  decimalPoint = '.',
  thousandSeparator = ',',
}) => {
  //  discuss at: http://phpjs.org/functions/number_format/
  // original by: Jonas Raoni Soares Silva (http://www.jsfromhell.com)
  // improved by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
  // improved by: davook
  // improved by: Brett Zamir (http://brett-zamir.me)
  // improved by: Brett Zamir (http://brett-zamir.me)
  // improved by: Theriault
  // improved by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
  // bugfixed by: Michael White (http://getsprink.com)
  // bugfixed by: Benjamin Lupton
  // bugfixed by: Allan Jensen (http://www.winternet.no)
  // bugfixed by: Howard Yeend
  // bugfixed by: Diogo Resende
  // bugfixed by: Rival
  // bugfixed by: Brett Zamir (http://brett-zamir.me)
  //  revised by: Jonas Raoni Soares Silva (http://www.jsfromhell.com)
  //  revised by: Luke Smith (http://lucassmith.name)
  //    input by: Kheang Hok Chin (http://www.distantia.ca/)
  //    input by: Jay Klehr
  //    input by: Amir Habibi (http://www.residence-mixte.com/)
  //    input by: Amirouche
  //   example 1: number_format(1234.56);
  //   returns 1: '1,235'
  //   example 2: number_format(1234.56, 2, ',', ' ');
  //   returns 2: '1 234,56'
  //   example 3: number_format(1234.5678, 2, '.', '');
  //   returns 3: '1234.57'
  //   example 4: number_format(67, 2, ',', '.');
  //   returns 4: '67,00'
  //   example 5: number_format(1000);
  //   returns 5: '1,000'
  //   example 6: number_format(67.311, 2);
  //   returns 6: '67.31'
  //   example 7: number_format(1000.55, 1);
  //   returns 7: '1,000.6'
  //   example 8: number_format(67000, 5, ',', '.');
  //   returns 8: '67.000,00000'
  //   example 9: number_format(0.9, 0);
  //   returns 9: '1'
  //  example 10: number_format('1.20', 2);
  //  returns 10: '1.20'
  //  example 11: number_format('1.20', 4);
  //  returns 11: '1.2000'
  //  example 12: number_format('1.2000', 3);
  //  returns 12: '1.200'
  //  example 13: number_format('1 000,50', 2, '.', ' ');
  //  returns 13: '100 050.00'
  //  example 14: number_format(1e-8, 8, '.', '');
  //  returns 14: '0.00000001'

  let newNumber = value;
  const newDecimals = decimals;
  newNumber = `${newNumber}`.replace(/[^0-9+\-Ee.]/g, '');
  const number = !isFinite(+newNumber) ? 0 : +newNumber;
  const prec = !isFinite(+newDecimals) ? 0 : Math.abs(newDecimals);
  const sep = thousandSeparator;
  const dec = decimalPoint;
  let sum = '';
  const toFixedFix = function (num, precision) {
    const power = Math.pow(10, precision);
    return `${(Math.round(num * power) / power).toFixed(precision)}`;
  };
  // Fix for IE parseFloat(0.55).toFixed(0) = 0;
  sum = (prec ? toFixedFix(number, prec) : `${Math.round(n)}`).split('.');
  if (sum[0].length > 3) {
    sum[0] = sum[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
  }
  if ((sum[1] || '').length < prec) {
    sum[1] = sum[1] || '';
    sum[1] += new Array(prec - sum[1].length + 1).join('0');
  }
  return sum.join(dec);
};

const removeCountDotThousand = (num) => {
  if (!num.includes('.')) return num;
  num = numberFormat({ value: num, decimals: 2 });
  // pembulatan next index
  return num;
};

const isDecimal = (result) => {
  if (result > Math.floor(result)) {
    return true;
  }
  return false;
};

export default {
  countLengthDecimal,
  getRandomInt,
  decideNumberFormatter,
  numberFormatter,
  bNumberFormatter,
  mNumberFormatter,
  kNumberFormatter,
  parseCountAbbrv3,
  parseLot,
  addPlusOrMinus2,
  parseCountAbbrv4,
  kNumberFormatterV2,
  numberFormat,
  isDecimal,
  parseCountAbbrv2,
};
