/* eslint-disable no-use-before-define */
import {
  ActionReducerMapBuilder,
  PayloadAction,
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import Router from 'next/router';

// API
import auth from 'lib/api/auth';
import credentials from 'lib/entities/credentials';
import handleErrorMessageAPI from 'global/AlertErrorMessage';
import { clevertapEvent, clevertapProfilingUser } from 'utils/clevertap';
import { verifyOTP } from 'lib/api/authNext';
import type { RootReducerState } from 'lib/rootReducer';

// Constants
import ERROR_MESSAGE from 'constants/errorMessage';
import SUCCESS_MESSAGE from 'constants/successMessage';
import noticeError from 'utils/logger';
import {
  formatErrorMessage,
  onUpdateAvatarIds,
  removeData,
  storeData,
} from './utils';
import {
  emailVerifyStatus,
  errorMessage,
  formTypes,
  mappingNewRegisterTypes,
  newRegisterTypes,
  phoneVerifyStatus,
  registerTypes,
  LS_EMAIL_REGISTER,
} from './constants';

// Store
import emailRegisterSlice from './EmailRegister/slice';

// Types
import { CommonPayload } from '../../../@types/action-payload';
import {
  RegisterState,
  StoreProfile,
  StoreSocialIdentities,
  StorePhoneNumber,
  StoreCaptchaToken,
  StoreEmailVerifData,
  IPayloadCode,
  IPayloadPhoneOTP,
  IPayloadRegister,
  IPayloadVerifyPhone,
  IResponseEmailOTP,
  IResponseMessage,
  IResponseRegister,
  IResponseVerifyPhone,
  SavePhoneNumber,
  VerifyPhoneErrorType,
} from './types';

// Actions
const CONTEXT = '@register';
const actionType = {
  SUBMIT: `${CONTEXT}/submit`,
  RESEND: `${CONTEXT}/resend`,
  VERIFY: `${CONTEXT}/verify`,
  VERIFY_REGISTRATION: `${CONTEXT}/verify_registration`,
  VERIFY_EMAIL_OTP: `${CONTEXT}/verify_email_otp`,
  RESEND_EMAIL_OTP: `${CONTEXT}/resend_email_otp`,
  VERIFY_PHONE: `${CONTEXT}/verify_phone`,
  VERIFY_PHONE_OTP: `${CONTEXT}/verify_phone_otp`,
  RESEND_PHONE_OTP: `${CONTEXT}/resend_phone_otp`,
};

// Initial state
const initialState: RegisterState = {
  currentForm: formTypes.SELECTION,
  type: registerTypes.EMAIL,
  accountid: '',
  accountkey: '',
  fullname: '',
  email: '',
  username: '',
  password: '',
  passwordConf: '',
  phoneNumber: '',
  channel: null,
  code: '62',
  country: '',
  exchange: '',
  supportId: '',
  captchaToken: '',
  captchaVersion: 3,
  tempKey: '',
  emailStatus: '',
};

// Reducers (define how the state changes)
const reducers = {
  changeForm: (
    state: RegisterState,
    action: PayloadAction<{ target: string }>,
  ) => {
    state.currentForm = action.payload.target;
  },
  storeProfile: (state: RegisterState, action: PayloadAction<StoreProfile>) => {
    const {
      nextForm,
      fullname,
      email,
      username,
      password,
      passwordConf,
      tempKey,
      emailStatus,
    } = action.payload;
    state.currentForm = nextForm;
    state.fullname = fullname;
    state.email = email;
    state.username = username;
    state.password = password;
    state.passwordConf = passwordConf;
    state.tempKey = tempKey;
    state.emailStatus = emailStatus;
  },
  storeSocialIdentities: (
    state: RegisterState,
    action: PayloadAction<StoreSocialIdentities>,
  ) => {
    const { type, accountid, accountkey, fullname, email, username, error } =
      action.payload;
    state.type = type;
    state.accountid = accountid;
    state.accountkey = accountkey;
    state.fullname = fullname;
    state.email = email;
    state.username = username;
    state.error = error;
  },
  storePhoneNumber: (
    state: RegisterState,
    action: PayloadAction<StorePhoneNumber>,
  ) => {
    const { phoneNumber, country } = action.payload;
    state.currentForm = formTypes.NOTIFY_VERIFICATION_PHONE;
    state.phoneNumber = phoneNumber;
    state.country = country.iso2;
  },
  storeCaptchaToken: (
    state: RegisterState,
    action: PayloadAction<StoreCaptchaToken>,
  ) => {
    state.captchaToken = action.payload.token;
  },
  storeEmailVerifData: (
    state: RegisterState,
    action: PayloadAction<StoreEmailVerifData>,
  ) => {
    state.tempKey = action.payload.tempKey;
    state.emailStatus = action.payload.emailStatus;
  },
  storeAllData: (state: RegisterState, action: PayloadAction<RegisterState>) =>
    action.payload,
  savePhoneNumber: (
    state: RegisterState,
    action: PayloadAction<SavePhoneNumber>,
  ) => {
    const { phoneNumber, code } = action.payload;
    state.currentForm = formTypes.SEND_OTP_METHOD;
    state.phoneNumber = phoneNumber;
    state.code = code;

    storeData({ ...state, currentForm: formTypes.PHONE });
  },
  resetAllData: () => initialState,
};

// Effects (thunks / async operations that needs side effects)
export const effects = {
  // verify registration
  verifyRegistration: createAsyncThunk<IResponseRegister, IPayloadRegister>(
    actionType.VERIFY_REGISTRATION,
    async (
      { fullname, email, username, password, verificationToken },
      { getState, rejectWithValue, dispatch },
    ) => {
      try {
        const {
          register: {
            root: { tempKey: prevTempKey, type, emailStatus },
          },
        } = getState() as RootReducerState;

        let response: CommonPayload;
        if (type === registerTypes.FACEBOOK) {
          response = await auth.verifyEmailRegistration(
            prevTempKey,
            email,
            newRegisterTypes.FACEBOOK,
          );
        } else {
          response = await auth.verifyRegistration({
            fullname,
            email,
            username,
            password,
            verificationToken,
          });
        }

        const {
          message,
          data: { key: tempKey, status },
        } = response.data;

        let nextForm: string = formTypes.VERIFY_EMAIL;

        if (status === 'verified') {
          nextForm = formTypes.PHONE;
        } else {
          handleErrorMessageAPI(message, SUCCESS_MESSAGE.ALERT_GREEN);
        }

        return {
          message,
          tempKey,
          nextForm,
          type,
          emailStatus,
        };
      } catch (error) {
        const message: string = error?.cause?.message;
        handleErrorMessageAPI(message, ERROR_MESSAGE.ALERT_RED);

        if (error?.cause?.errors) {
          error?.cause?.errors?.map((e) =>
            dispatch(
              emailRegisterSlice.actions.storeFieldValidation({
                field: e?.key?.toLowerCase(),
                message: e?.error,
                error: e?.error,
              }),
            ),
          );
        }

        return rejectWithValue(message);
      }
    },
  ),
  // verify email otp
  validateEmailOtp: createAsyncThunk<IResponseEmailOTP, IPayloadCode>(
    actionType.VERIFY_EMAIL_OTP,
    async ({ code }, { getState, dispatch }) => {
      const {
        register: { root },
      } = getState() as RootReducerState;
      try {
        const { tempKey } = root;

        const response = await auth.validateEmailOtp(tempKey, code);

        if (response && !response.data) {
          throw new Error('Could not fetch data');
        }

        const {
          error_type,
          message,
          data: { valid, state: emailStatus },
        } = response.data;

        if (emailStatus === emailVerifyStatus.SIGNIN) {
          throw new Error(errorMessage.EMAIL_REGISTERED);
        }

        if (error_type || !valid) {
          throw new Error(message);
        }

        return {
          message: response.data.message,
          root,
        };
      } catch (error) {
        const message = error?.cause?.message;
        if (message === errorMessage.EMAIL_REGISTERED) {
          handleErrorMessageAPI(
            errorMessage.EMAIL_REGISTERED,
            ERROR_MESSAGE.ALERT_RED,
          );
          localStorage.setItem(LS_EMAIL_REGISTER, root.email);
          Router.push('/login');

          // remove previous data from register form
          dispatch(rootSlice.actions.resetAllData());
          removeData();
          return Promise.reject(message);
        }

        handleErrorMessageAPI(message, ERROR_MESSAGE.ALERT_RED);
        return Promise.reject(message);
      }
    },
  ),
  // resend email otp
  resendEmailOtp: createAsyncThunk<IResponseMessage, void>(
    actionType.RESEND_EMAIL_OTP,
    async (_, { getState }) => {
      try {
        const {
          register: {
            root: { tempKey, email, type },
          },
        } = getState() as RootReducerState;

        const response = await auth.verifyEmailRegistration(
          tempKey,
          email,
          mappingNewRegisterTypes[type],
        );

        const { message } = response.data;

        handleErrorMessageAPI(message, SUCCESS_MESSAGE.ALERT_GREEN);

        return {
          message,
        };
      } catch (error) {
        const message = error?.cause?.message;
        handleErrorMessageAPI(message, ERROR_MESSAGE.ALERT_RED);
        return Promise.reject(message);
      }
    },
  ),
  // verify phone
  verifyPhone: createAsyncThunk<IResponseVerifyPhone, IPayloadVerifyPhone>(
    actionType.VERIFY_PHONE,
    async ({ phoneNumber, code, channel }, { getState, dispatch }) => {
      try {
        if (!phoneNumber || !code) {
          throw new Error('Phone number is required!');
        }

        const {
          register: { root },
        } = getState() as RootReducerState;
        const { tempKey: prevTempKey } = root;

        const response = await auth.verifyPhoneRegistration(
          prevTempKey || undefined,
          code,
          phoneNumber.slice(code.length),
          channel,
        );

        if (response && !response.data) {
          throw new Error('Could not fetch data');
        }

        const {
          error_type,
          message,
          data: { key: tempKey },
        } = response.data;

        if (error_type) {
          throw new Error(message);
        }

        handleErrorMessageAPI(message, SUCCESS_MESSAGE.ALERT_GREEN);

        return {
          message,
          tempKey,
          phoneNumber,
          code,
          channel,
          root,
        };
      } catch (error) {
        const errorType = error?.cause?.errorType;
        const message = error?.cause?.message;

        if (errorType === VerifyPhoneErrorType.OtpLimit) {
          dispatch(
            rootSlice.actions.changeForm({
              target: formTypes.OTP_LIMIT,
            }),
          );
        } else {
          dispatch(
            rootSlice.actions.changeForm({
              target: formTypes.PHONE,
            }),
          );
          handleErrorMessageAPI(message, ERROR_MESSAGE.ALERT_RED);
        }

        return Promise.reject(message);
      }
    },
  ),
  // validate phone otp
  validatePhoneOtp: createAsyncThunk<IResponseMessage, IPayloadPhoneOTP>(
    actionType.VERIFY_PHONE_OTP,
    async ({ otp }, { getState, dispatch }) => {
      try {
        const {
          register: {
            root: { tempKey, type },
          },
        } = getState() as RootReducerState;

        const response = await verifyOTP({ key: tempKey, otp });

        if (response.data.error_type) {
          throw new Error(response.data.message);
        }

        const { status } = response.data.data;
        if (status === phoneVerifyStatus.FAIL) {
          dispatch(
            rootSlice.actions.changeForm({
              target: formTypes.PHONE_ERROR,
            }),
          );
          throw new Error(errorMessage.PHONE_REGISTERED);
        }

        if (status !== phoneVerifyStatus.SUCCESS) {
          throw new Error(errorMessage.FAIL_TO_VERIFY_PHONE);
        }

        clevertapEvent('Register Successful', {
          'Register Method': type,
        });

        const {
          user: { username, email, avatar, id },
        } = response.data.data;

        onUpdateAvatarIds(id);
        removeData();

        // create clevertap's user profile after success registration
        // Track if username is empty
        if (!username) {
          noticeError(
            new Error('[CT initialization Error]: username is empty'),
            {
              place: 'src/features/register/slice.ts',
              event: 'validatePhoneOtp',
              eventParam: { profile: response.data.data },
            },
          );
        }
        // @ts-ignore
        clevertapProfilingUser({
          isInitProfile: true,
          identity: username,
          email,
          profilePictureUrl: avatar,
        });

        // save userdata to credentials entities
        dispatch(credentials.actions.loadCredentialsExodus(response.data.data));

        if (type === registerTypes.GOOGLE || type === registerTypes.FACEBOOK) {
          dispatch(rootSlice.actions.changeForm({ target: formTypes.SUCCESS }));
        } else {
          handleErrorMessageAPI(
            response.data.message,
            SUCCESS_MESSAGE.ALERT_GREEN,
          );
        }

        return {
          message: response.data.message,
        };
      } catch (error) {
        const message = formatErrorMessage(error);

        if (message === errorMessage.PHONE_REGISTERED) {
          return Promise.reject(message);
        }

        handleErrorMessageAPI(message, ERROR_MESSAGE.ALERT_RED);
        return Promise.reject(message);
      }
    },
  ),
  // resend phone otp
  resendPhoneOtp: createAsyncThunk<IResponseMessage, void>(
    actionType.RESEND_PHONE_OTP,
    async (_, { getState }) => {
      try {
        const {
          register: {
            root: { tempKey, phoneNumber, code, channel },
          },
        } = getState() as RootReducerState;

        const response = await auth.verifyPhoneRegistration(
          tempKey,
          String(code),
          phoneNumber.slice(String(code).length),
          channel,
        );

        if (response && !response.data) {
          throw new Error('Could not fetch data');
        }

        const { error_type, message } = response.data;

        if (error_type) {
          throw new Error(message);
        }

        handleErrorMessageAPI(message, SUCCESS_MESSAGE.ALERT_GREEN);

        return {
          message,
        };
      } catch (error) {
        const message = error?.cause?.message;
        handleErrorMessageAPI(message, ERROR_MESSAGE.ALERT_RED);
        return Promise.reject(message);
      }
    },
  ),
};

// Selectors (getters)
export const selectors = createSelector(
  (state) => state.register.root,
  (root: RegisterState) => root,
);

// Extra reducers (handle actions that are not defined in this slice)
const extraReducers = (builder: ActionReducerMapBuilder<RegisterState>) => {
  builder
    .addCase(effects.verifyRegistration.pending, (state: RegisterState) => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(
      effects.verifyRegistration.fulfilled,
      (state: RegisterState, action: PayloadAction<any, any, any>) => {
        const { fullname, email, username, password, passwordConf } =
          action.meta.arg;
        state.isLoading = false;
        state.fullname = fullname;
        state.email = email;
        state.username = username;
        state.password = password;
        state.passwordConf = passwordConf;
        state.tempKey = action.payload.tempKey;
        state.currentForm = action.payload.nextForm;
        state.type = action.payload.type;
        state.emailStatus = action.payload.emailStatus;

        storeData({
          ...action.payload.root,
          isLoading: false,
          fullname,
          email,
          username,
          password,
          passwordConf,
          tempKey: action.payload.tempKey,
          type: action.payload.type,
          emailStatus: action.payload.emailStatus,
          currentForm: action.payload.nextForm,
        });
      },
    )
    .addCase(
      effects.verifyRegistration.rejected,
      (
        state: RegisterState,
        action: PayloadAction<CommonPayload, string, any, any>,
      ) => {
        state.isLoading = false;
        state.error = action.error;
      },
    )
    .addCase(effects.validateEmailOtp.pending, (state: RegisterState) => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(
      effects.validateEmailOtp.fulfilled,
      (
        state: RegisterState,
        action: PayloadAction<IResponseEmailOTP, string, any, any>,
      ) => {
        state.currentForm = formTypes.PHONE;
        state.isLoading = false;
        storeData({
          ...action.payload.root,
          isLoading: false,
          currentForm: formTypes.PHONE,
        });
      },
    )
    .addCase(
      effects.validateEmailOtp.rejected,
      (
        state: RegisterState,
        action: PayloadAction<CommonPayload, string, any, any>,
      ) => {
        state.isLoading = false;
        state.error = action.error;
      },
    )
    .addCase(effects.verifyPhone.pending, (state: RegisterState) => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(
      effects.verifyPhone.fulfilled,
      (
        state: RegisterState,
        action: PayloadAction<IResponseVerifyPhone, string, any, any>,
      ) => {
        state.isLoading = false;
        state.tempKey = action.payload.tempKey;
        state.phoneNumber = action.payload.phoneNumber;
        state.code = action.payload.code;
        state.channel = action.payload.channel;
        state.currentForm = formTypes.VERIFY_PHONE;
        storeData({
          ...action.payload.root,
          isLoading: false,
          phoneNumber: action.payload.phoneNumber,
          code: action.payload.code,
          tempKey: action.payload.tempKey,
          currentForm: formTypes.VERIFY_PHONE,
        });
      },
    )
    .addCase(
      effects.verifyPhone.rejected,
      (
        state: RegisterState,
        action: PayloadAction<CommonPayload, string, any, any>,
      ) => {
        state.isLoading = false;
        state.error = action.error;
      },
    );
};

export const rootSlice = createSlice({
  name: 'register',
  initialState,
  reducers,
  extraReducers,
});

export default rootSlice;
